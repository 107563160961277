import { render, staticRenderFns } from "./Success.vue?vue&type=template&id=4a2a75cf&scoped=true"
import script from "./Success.vue?vue&type=script&lang=ts"
export * from "./Success.vue?vue&type=script&lang=ts"
import style0 from "./Success.vue?vue&type=style&index=0&id=4a2a75cf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a2a75cf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnDirection: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/btn-direction.vue').default})
