
import Vue from 'vue'

import { Context } from '@nuxt/types'
import { MetaInfo } from 'vue-meta'

import { MemberType, WorkType } from '@/models'
import { handleError } from '@/plugins/helper'
import About from '@/sections/top/about.vue'
import Banner from '@/sections/top/banner.vue'
import CommunityTop from '@/sections/top/community.vue'
import Feature from '@/sections/top/feature.vue'
import KeyVisualAnimation from '@/sections/top/kv-animation.vue'
import KeyVisual from '@/sections/top/kv.vue'
import NewsSticky from '@/sections/top/news-sticky.vue'
import News from '@/sections/top/news.vue'
import Scientist from '@/sections/top/scientist.vue'
import SloganTop from '@/sections/top/slogan.vue'
import Story from '@/sections/top/story.vue'
import TechPostTop from '@/sections/top/tech-posts.vue'
import Work from '@/sections/top/work.vue'

import config from '@/config'

export default Vue.extend({
  name: 'HomePage',
  components: {
    SloganTop,
    KeyVisual,
    KeyVisualAnimation,
    About,
    Work,
    Story,
    News,
    Banner,
    Scientist,
    Feature,
    NewsSticky,
    TechPostTop,
    CommunityTop
  },
  async asyncData({ $content }: Context): Promise<object | void> {
    const [works] = await Promise.all([
      $content('work/detail')
        .sortBy('publishedAt', 'desc')
        .only(['eyeCatchImage', 'title', 'members', 'tags', 'slug', 'publishedAtStr'])
        .limit(5)
        .fetch<WorkType[]>()
        .catch(handleError)
    ])

    // @ts-ignore
    const memberSysIdList: string[] = works ? works.reduce((a, c) => [...a, ...c.members], []) : []

    const members = await $content('members')
      .where({ sysId: { $in: [...memberSysIdList] } })
      .fetch()
      .catch(handleError)

    return { works, members }
  },
  data() {
    const works = [] as WorkType[]
    const members = [] as MemberType[]

    return {
      works,
      members
    }
  },
  head(): MetaInfo {
    const title = 'DeNA×AI｜技術と、革新と、未来と。'
    const extendMeta = this.$helper.setMetaTitleAndDescription(title, config.description, false)

    return {
      title: 'DeNA×AI｜技術と、革新と、未来と。',
      titleTemplate: undefined,
      meta: [...extendMeta]
    }
  },
  computed: {
    tagFilter(): string {
      const { tag } = this.$route.query
      return tag.toString().toLowerCase() || 'all'
    }
  }
})
