import { render, staticRenderFns } from "./tech-posts.vue?vue&type=template&id=39f405c6&scoped=true"
import script from "./tech-posts.vue?vue&type=script&lang=ts"
export * from "./tech-posts.vue?vue&type=script&lang=ts"
import style0 from "./tech-posts.vue?vue&type=style&index=0&id=39f405c6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39f405c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TechPostList: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/tech-post/list.vue').default,BtnDirection: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/btn-direction.vue').default})
