import { render, staticRenderFns } from "./Paging.vue?vue&type=template&id=ed432af6&scoped=true"
import script from "./Paging.vue?vue&type=script&lang=ts"
export * from "./Paging.vue?vue&type=script&lang=ts"
import style0 from "./Paging.vue?vue&type=style&index=0&id=ed432af6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed432af6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageShare: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/page-share.vue').default,ListControl: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/list-control.vue').default})
