import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=916d1e00&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=ts"
export * from "./Footer.vue?vue&type=script&lang=ts"
import style0 from "./Footer.vue?vue&type=style&index=0&id=916d1e00&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "916d1e00",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnLink: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/btn-link.vue').default})
