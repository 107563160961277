
import Vue from 'vue'

import Logo from '@/components/Logo.vue'

export default Vue.extend({
  name: 'TopKeyVisualAnimtion',
  components: { Logo },
  data() {
    return {
      screenHeight: 0
    }
  },
  computed: {
    positionY(): number {
      return this.screenHeight / 2
    }
  },
  mounted() {
    this.screenHeight = window.innerHeight || screen.height

    // window.addEventListener('scroll', () => {
    //   const kv = this.$el
    //   const kvHeight = kv.clientHeight
    //   const scrollHeight = window.scrollY
    //
    //   if (scrollHeight > kvHeight + 100) {
    //     kv.classList.add('hidden')
    //   }
    // })
  }
})
