import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=503b0991&scoped=true"
import script from "./_slug.vue?vue&type=script&lang=ts"
export * from "./_slug.vue?vue&type=script&lang=ts"
import style0 from "./_slug.vue?vue&type=style&index=0&id=503b0991&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "503b0991",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHeading: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/page-heading.vue').default,CBtn: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/content/c-btn.vue').default,ProjectMember: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/project-member.vue').default,PageShare: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/page-share.vue').default,ListControl: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/list-control.vue').default})
