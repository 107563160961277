import { render, staticRenderFns } from "./News.vue?vue&type=template&id=e97df35a&scoped=true"
import script from "./News.vue?vue&type=script&lang=ts"
export * from "./News.vue?vue&type=script&lang=ts"
import style0 from "./News.vue?vue&type=style&index=0&id=e97df35a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e97df35a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnDirection: require('/_work/runner/dena.ai.renewal/dena.ai.renewal/frontend/components/common/btn-direction.vue').default})
