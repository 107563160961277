
export default {
  name: 'BtnRedirect',
  props: {
    lable: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_blank'
    }
  }
}
